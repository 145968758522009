import React from 'react';
import Link from '../navigation/Link';
import {Trans, t} from '@lingui/macro';
import {ROUTES} from '../../constants/navigation';
import ModuleWrapper from '../sessionProvider/ModuleWrapper';
import { ENTITY } from '../../constants/modules';

/**
 * @fero
 */

class Footer extends React.PureComponent {

    render() {
        return <ModuleWrapper mod={ENTITY.ESHOP}>
            <div className="footer">
                <Link className="px-2 text-dark footer-link" to={ROUTES.COMMERCIAL_TERMS}><Trans>Obchodné podmienky</Trans></Link>
                <Link className="px-2 text-dark footer-link" to={ROUTES.PERSONAL_DATA_PROTECTION}><Trans>Zásady ochrany osobných údajov</Trans></Link>
                <Link className="px-2 text-dark footer-link" to={ROUTES.CLAIM_RULES}><Trans>Reklamačný poriadok</Trans></Link>
            </div>
        </ModuleWrapper>;
    }

}

export default Footer;