/**
 * @fero
 */
export const APP_ROOT = '/app';
export const API_ROOT = APP_ROOT + '/api.php';
export const PUBLIC_DIR = '/public/'; 
export const UPLOAD_TARGET = APP_ROOT + '/upload.php';
export const DOWNLOAD_TARGET = APP_ROOT + '/download.php';
export const PDF_TARGET = APP_ROOT + '/pdf.php';
export const EXPORT_TARGET = APP_ROOT + '/export.php';
export const EXPORT_REPORT_TARGET = APP_ROOT + '/report_excel.php';
export const IMPORT_TARGET = APP_ROOT + '/import.php';
export const GP_PAYMENT_TARGET = APP_ROOT + '/gp_request.php';
export const STOCK_ROOT = '/stock';
export const LOGOUT_RELATIVE_PATH = '/user/logout';
export const SETUP_MAIL_TARGET = APP_ROOT + '/setup_mail.php';

export const API_ENDPOINTS = {
    MANUFACTURERS_ALL: '/manufacturer/all',
    MANUFACTURERS_ADD: '/manufacturer/add',
    MANUFACTURERS_EDIT: '/manufacturer/edit',
    MANUFACTURERS_REMOVE: '/manufacturer/remove',
    FLAG_ALL: '/flag/all',
    FLAG_ADD: '/flag/add',
    FLAG_EDIT: '/flag/edit',
    FLAG_REMOVE: '/flag/remove',
    DEPARTMENT_ALL: '/department/all',
    DEPARTMENT_ADD: '/department/add',
    DEPARTMENT_EDIT: '/department/edit',
    DEPARTMENT_REMOVE: '/department/remove',
    FINSTAT_DETAILS: '/finstat/details',
    COUNTRY_ALL: '/country/all',
    USER_EMPLOYEES: '/user/employees',
    USER_DEALERS: '/user/dealers',
    USER_SUPERVISORS: '/user/supervisors',
    USER_LOGIN: '/user/login',
    USER_FORGOTTEN_PASSWORD: '/user/forgotten_password',
    USER_SEND_LOGIN_TOKEN: '/user/send_login_token',
    USER_LOGOUT: LOGOUT_RELATIVE_PATH,
    USER_ADD: '/user/add',
    USER_CHANGE_PASSWORD: '/user/change_password',
    USER_ACTIVATE: '/user/activate',
    USER_SEND_ACTIVATION_EMAIL: '/user/send_activation_email',
    USER_SEND_PIN_CHANGE_EMAIL: '/user/send_pin_change_email',
    USER_CURRENT: '/user/current',
    USER_ALL: '/user/all',
    USER_DETAILS: '/user/details',
    USER_EDIT: '/user/edit',
    USER_REMOVE: '/user/remove',
    USER_GENERATE_API_KEY: '/user/generate_api_key',
    ACCOUNT_ADDRESSES: '/user/addresses',
    ADDRESS_ADD: '/address/add',
    ADDRESS_REMOVE: '/address/remove',
    ADDRESS_EDIT: '/address/edit',
    ALLEGRO_CATEGORIES: '/allegro/categories',
    ALLEGRO_SUBTREE: '/allegro/subtree',
    ALLEGRO_PARAMETERS: '/allegro/parameters',
    ALLEGRO_SETUP_PARAMETER: '/allegro/setup_parameter',
    ALLEGRO_COLUMNS: '/allegro/columns',
    CATEGORY_ALL: '/category/all',
    CATEGORY_TREE: '/category/tree',
    CATEGORY_DETAILS: '/category/details',
    CATEGORY_ADD: '/category/add',
    CATEGORY_EDIT: '/category/edit',
    CATEGORY_REMOVE: '/category/remove',
    CATEGORY_EDIT_HTML: '/category/edit_html',
    DISCOUNT_GROUPS: '/discount/groups',
    DISCOUNT_TREE: '/discount/tree',
    DISCOUNT_RULES: '/discount/rules',
    DISCOUNT_EDIT_RULE: '/discount/edit_rule',
    DISCOUNT_REMOVE_RULE: '/discount/remove_rule',
    DISCOUNT_EDIT_GROUP: '/discount/edit_group',
    DISCOUNT_ADD_GROUP: '/discount/add_group',
    DISCOUNT_REMOVE_GROUP: '/discount/remove_group',
    SETTINGS_SESSION: '/settings/session',
    RIGHTS_ALL: '/rights/all',
    COMPANY_ADDRESSES: '/company/addresses',
    COMPANY_COMMERCIAL_TERMS: '/company/commercial_terms',
    COMPANY_EDIT_COMMERCIAL_TERMS: '/company/edit_commercial_terms',
    COMPANY_RETURN_CONDITIONS: '/company/return_conditions',
    COMPANY_EDIT_RETURN_CONDITIONS: '/company/edit_return_conditions',
    COMPANY_PRIVACY_POLICY: '/company/privacy_policy',
    COMPANY_EDIT_PRIVACY_POLICY: '/company/edit_privacy_policy',
    CUSTOMER_ALL: '/customer/all',
    CUSTOMER_DETAILS: '/customer/details',
    CUSTOMER_ADDRESSES: '/customer/addresses',
    CUSTOMER_USERS: '/customer/users',
    CUSTOMER_TYPES: '/customer/types',
    CUSTOMER_GROUPS: '/customer/groups',
    CUSTOMER_ADD_GROUP: '/customer/add_group',
    CUSTOMER_EDIT_GROUP: '/customer/edit_group',
    CUSTOMER_REMOVE_GROUP: '/customer/remove_group',
    CUSTOMER_ADD: '/customer/add',
    CUSTOMER_EDIT: '/customer/edit',
    CUSTOMER_REMOVE: '/customer/remove',
    PRODUCT_SEARCH: '/product/search',
    PRODUCT_ADD: '/product/add',
    PRODUCT_INDEX: '/product/index',
    PRODUCT_TYPES: '/product/types',
    PRODUCT_EDIT: '/product/edit',
    PRODUCT_EDIT_QUANTITY_UNIT: '/product/edit_quantity_units',
    PRODUCT_REMOVE: '/product/remove',
    PRODUCT_EDIT_SPECIAL_PRICE: '/product/edit_special_price',
    PRODUCT_REMOVE_SPECIAL_PRICE: '/product/remove_special_price',
    PRODUCT_PRICE: '/product/price',
    PRODUCT_EXPLAIN_PRICE: '/product/explain_price',
    PRODUCT_EXPLAIN_AVAILABILITY: '/product/explain_availability',
    PRODUCT_FILTERS: '/product/filters',
    PRODUCT_STOCK_OPTIONS: '/product/stock_options',
    PRODUCT_REFRESH_STOCK: '/product/refresh_stock',
    PRODUCT_DETAILS: '/product/details',
    PRODUCT_TRANSFERS: '/product/transfers',
    PRODUCT_ORDERS: '/product/orders',
    PRODUCT_QUOTATIONS: '/product/quotations',
    PRODUCT_REQUESTS: '/product/requests',
    PRODUCT_PRODUCTS: '/product/products',
    PRODUCT_SALES_HISTORY: '/product/sales_history',
    PRODUCT_STOCK_DETAILS: '/product/stock_detail',
    PRODUCT_EDIT_HTML: '/product/edit_html',
    PRODUCT_EDIT_PARAM: '/product/edit_param',
    PRODUCT_EDIT_CUSTOMER_CODE: '/product/edit_custom_code',
    PRODUCT_REMOVE_CUSTOM_CODE: '/product/remove_custom_code',
    PRODUCT_EDIT_BARCODE: '/product/edit_barcode',
    PRODUCT_PURCHASE_PRICES: '/product/purchase_prices',
    PRODUCT_CROSS_REFERENCES: '/product/cross_references',
    PRODUCT_COMPETITORS: '/product/competitors',
    PRODUCT_REPLACEMENTS: '/product/replacements',
    PRODUCT_ADD_REPLACEMENT: '/product/add_replacement',
    PRODUCT_REMOVE_REPLACEMENT: '/product/remove_replacement',
    PRODUCT_RELATIVES: '/product/relatives',
    PRODUCT_ADD_RELATIVE: '/product/add_relative',
    PRODUCT_REMOVE_RELATIVE: '/product/remove_relative',
    PRODUCT_VARIANTS: '/product/variants',
    PRODUCT_ADD_VARIANT: '/product/add_variant',
    PRODUCT_REMOVE_VARIANT: '/product/remove_variant',
    TAX_RATES: '/tax/rates',
    PARAMETER_ALL: '/parameter/all',
    PARAMETER_ADD: '/parameter/add',
    PARAMETER_EDIT: '/parameter/edit',
    PARAMETER_VALUES: '/parameter/values',
    PARAMETER_DETAILS: '/parameter/details',
    DASHBOARD_VARIABLES: '/dashboard/variables',
    DASHBOARD_ITEMS: '/dashboard/items',
    DASHBOARD_ITEM: '/dashboard/item',
    TASK_ALL: '/task/all',
    TASK_ADD: '/task/add',
    TASK_EDIT: '/task/edit',
    TASK_REMOVE: '/task/remove',
    WAGE_ALL: '/wage/all',
    WAGE_SUMMARY: '/wage/summary',
    WAGE_EDIT: '/wage/edit',
    ASSIGNMENT_ALL: '/assignment/all',
    ASSIGNMENT_ADD: '/assignment/add',
    ASSIGNMENT_EDIT: '/assignment/edit',
    ASSIGNMENT_SIGN: '/assignment/sign',
    ASSIGNMENT_REOPEN: '/assignment/reopen',
    ASSIGNMENT_RELOAD: '/assignment/reload',
    ASSIGNMENT_REMOVE: '/assignment/remove',
    CART_MOVE: '/cart/move',
    CART_CURRENT: '/cart/current',
    CART_ADD_ITEMS: '/cart/add_items',
    CART_ADD_ITEM: '/cart/add_item',
    CART_EDIT_ITEM: '/cart/edit_item',
    CART_EDIT_REQUESTED_AT: '/cart/edit_requested_at',
    CART_REMOVE_ITEMS: '/cart/remove_items',
    CART_SAVE: '/cart/save',
    CART_SAVE_AS: '/cart/save_as',
    CART_SHARE: '/cart/share',
    PREDICTION_METHODS: '/prediction/methods',
    PREDICTION_FILL_CART: '/prediction/fill_cart',
    PAYMENT_METHODS: '/payment/methods',
    TRANSPORT_TYPES: '/transport/types',
    TRANSPORT_PICKUP_POINTS: '/transport/pickup_points',
    LICENSE_MODULES: '/license/modules',
    LICENSE_MODULE_DETAILS: '/license/module_details',
    LICENSE_SUBSCRIPTIONS: '/license/subscriptions',
    LICENSE_SUBSCRIBE: '/license/subscribe',
    LICENSE_UNSUBSCRIBE: '/license/unsubscribe',
    LICENSE_ADD_MODULE: '/license/add_module',
    LICENSE_EDIT_MODULE: '/license/edit_module',
    LICENSE_REMOVE_MODULE: '/license/remove_module',
    LICENSE_EDIT_PRICE: '/license/edit_price',
    ORDER_OUTGOING: '/order/outgoing',
    ORDER_INCOMING: '/order/incoming',
    ORDER_ALL: '/order/all',
    ORDER_LINES: '/order/lines',
    ORDER_STATES: '/order/states',
    ORDER_LINE_STATES: '/order/line_states',
    ORDER_DETAILS: '/order/details',
    ORDER_PREVIEW: '/order/preview',
    ORDER_FROM_CART: '/order/from_cart',
    ORDER_FROM_QUOTATION: '/order/from_quotation',
    ORDER_ADD: '/order/add',
    ORDER_FROM_INCOMING: '/order/from_incoming',
    ORDER_EDIT: '/order/edit',
    ORDER_REMOVE: '/order/remove',
    ORDER_RESET_PRICES: '/order/reset_prices',
    ORDER_CANCEL: '/order/cancel',
    ORDER_DISPATCH: '/order/dispatch',
    ORDER_SPLIT: '/order/split',
    ORDER_FIT_PRICES: '/order/fit_prices',
    ORDER_ADD_ITEM: '/order/add_item',
    ORDER_EDIT_ITEM: '/order/edit_item',
    ORDER_REMOVE_ITEM: '/order/remove_item',
    ORDER_ADD_RESERVATION: '/order/add_reservation',
    ORDER_REMOVE_RESERVATION: '/order/remove_reservation',
    ORDER_SEND: '/order/send',
    ORDER_LOAD_TO_CART: '/order/load_to_cart',
    REQUEST_ALL: '/request/all',
    REQUEST_INCOMING: '/request/incoming',
    REQUEST_OUTGOING: '/request/outgoing',
    REQUEST_DETAILS: '/request/details',
    REQUEST_PREVIEW: '/request/preview',
    REQUEST_STATES: '/request/states',
    REQUEST_REMOVE: '/request/remove',
    REQUEST_SEND: '/request/send',
    REQUEST_ADD: '/request/add',
    REQUEST_EDIT: '/request/edit',
    REQUEST_FROM_CART: '/request/from_cart',
    REQUEST_FROM_INCOMING: '/request/from_incoming',
    REQUEST_ADD_ITEM: '/request/add_item',
    REQUEST_EDIT_ITEM: '/request/edit_item',
    REQUEST_REMOVE_ITEM: '/request/remove_item',
    REQUEST_LOAD_TO_CART: '/request/load_to_cart',
    QUOTATION_FROM_REQUEST: '/quotation/from_request',
    QUOTATION_ALL: '/quotation/all',
    QUOTATION_OUTGOING: '/quotation/outgoing',
    QUOTATION_INCOMING: '/quotation/incoming',
    QUOTATION_DETAILS: '/quotation/details',
    QUOTATION_PREVIEW: '/quotation/preview',
    QUOTATION_STATES: '/quotation/states',
    QUOTATION_DIVISIBILITY: '/quotation/divisibility',
    QUOTATION_FROM_CART: '/quotation/from_cart',
    QUOTATION_FROM_INCOMING: '/quotation/from_incoming',
    QUOTATION_EDIT: '/quotation/edit',
    QUOTATION_REMOVE: '/quotation/remove',
    QUOTATION_REOPEN: '/quotation/reopen',
    QUOTATION_REMOVE_ITEM: '/quotation/remove_item',
    QUOTATION_COPY: '/quotation/copy',
    QUOTATION_SEND: '/quotation/send',
    QUOTATION_FIT_PRICES: '/quotation/fit_prices',
    QUOTATION_ADD_ITEM: '/quotation/add_item',
    QUOTATION_EDIT_ITEM: '/quotation/edit_item',
    QUOTATION_LOAD_TO_CART: '/quotation/load_to_cart',
    UPLOAD_FILES: '/upload/files',
    NEWS_ALL: '/news/all',
    NEWS_DETAILS: '/news/details',
    NEWS_ADD: '/news/add',
    NEWS_EDIT: '/news/edit',
    NEWS_REMOVE: '/news/remove',
    NOTES_SEARCH: '/notes/search',
    NOTES_ADD: '/notes/add',
    NOTES_EDIT: '/notes/edit',
    NOTES_REMOVE: '/notes/remove',
    SHOPPING_LIST_ALL: '/shoppingList/all',
    SHOPPING_LIST_DETAILS: '/shoppingList/details',
    SHOPPING_LIST_EDIT_ITEM: '/shoppingList/edit_item',
    SHOPPING_LIST_REMOVE: '/shoppingList/remove',
    SHOPPING_LIST_EDIT: '/shoppingList/edit',
    SHOPPING_LIST_REMOVE_ITEMS: '/shoppingList/remove_items',
    SHOPPING_LIST_LOAD_ITEMS: '/shoppingList/load_items',
    SHOPPING_LIST_SHARE: '/shoppingList/share',
    REPORT_ALL: '/report/all',
    REPORT_COLUMNS: '/report/columns',
    REPORT_OPERATIONS: '/report/operations',
    REPORT_TABLES: '/report/tables',
    REPORT_ADD: '/report/add',
    REPORT_EDIT: '/report/edit',
    REPORT_RUN: '/report/run',
    REPORT_REMOVE: '/report/remove',
    ATTACHMENT_ALL: '/attachment/all',
    ATTACHMENT_ADD: '/attachment/add',
    ATTACHMENT_EDIT: '/attachment/edit',
    ATTACHMENT_REMOVE: '/attachment/remove',
    STOCK_ALL: '/stock/all',
    STOCK_DEFAULT: '/stock/default',
    SETTINGS_ALL: '/settings/all',
    SETTINGS_EDIT: '/settings/edit',
    CURRENCY_ALL: '/currency/all',
    FORM_ALL: '/form/all',
    FORM_DETAILS: '/form/details',
    FORM_RUN: '/form/run',
    FORM_ADD: '/form/add',
    FORM_EDIT: '/form/edit',
    FORM_REMOVE: '/form/remove',
    CHANNEL_ALL: '/channel/all',
    DEAL_ALL: '/deal/all',
    DEAL_DOCUMENTS: '/deal/documents',
    BACKUP_STATUS: '/backup/status',
    BACKUP_SAVE: '/backup/save',
    BACKUP_SAVE_JOURNAL: '/backup/save_journal',
    BACKUP_RESTORE: '/backup/restore',
    LANGUAGE_ALL: '/language/all',
    TRANSFER_TYPES: '/transfer/types',
    TRANSFER_REMOVE_RESERVATION: '/transfer/remove_reservation',
    INVENTORY_ADD_RESERVATION: '/inventory/add_reservation',
    INVENTORY_REMOVE_RESERVATION: '/inventory/remove_reservation',
    INVENTORY_CLASSES: '/inventory/classes',
    INVENTORY_ADD_CLASS: '/inventory/add_class',
    INVENTORY_EDIT_CLASS: '/inventory/edit_class',
    INVENTORY_REMOVE_CLASS: '/inventory/remove_class',
    INVENTORY_REFRESH_CLASSES: '/inventory/refresh_classes',
    HISTORY_RECORDS: '/history/records',
    ORSR_SEARCH: '/orsr/search',
    INVOICE_ALL: '/invoice/all',
    INVOICE_DETAILS: '/invoice/details',
    INVOICE_CLASSES: '/invoice/classes',
    INVOICE_ADD_PROFORMA: '/invoice/add_proforma',
    INVOICE_TYPES: '/invoice/types',
    ZEBRA_PRINT_PRODUCT_LABEL: '/zebra/print_product_label',
    ANALYSIS_TYPES: '/analysis/types',
    ANALYSIS_GENERATE: '/analysis/generate',
    VEHICLE_ALL: '/vehicle/all',
    VEHICLE_ADD: '/vehicle/add',
    VEHICLE_EDIT: '/vehicle/edit',
    VEHICLE_REMOVE: '/vehicle/remove',
    VEHICLE_FUEL_TYPES: '/vehicle/fuel_types',
    EVENT_ALL: '/event/all',
    EVENT_DETAILS: '/event/details',
    EVENT_ADD: '/event/add',
    EVENT_EDIT: '/event/edit',
    EVENT_EDIT_DESCRIPTION: '/event/edit_description',
    EVENT_SEND: '/event/send',
    EVENT_REMOVE: '/event/remove',
    EVENT_TYPES: '/event/types',
    EVENT_ADD_TYPE: '/event/add_type',
    EVENT_EDIT_TYPE: '/event/edit_type',
    EVENT_REMOVE_TYPE: '/event/remove_type',
    ALERT_ALL: '/alert/all',
    ALERT_TYPES: '/alert/types',
    ALERT_ACK: '/alert/ack',
};