export const ENTITY = {
    ESHOP: 'eshop',
    QUOTATIONS: 'quotations',
    REPORTS: 'reports',
    ANALYSIS: 'analysis',
    EVENTS: 'events',
    ALERTS: 'alerts',
    ASSIGNMENTS: 'assigns',
    FORMS: 'forms',
    STOCK: 'stock',
    ZEBRA: 'zebra',
    ALLEGRO: 'allegro',
    ACCOUNTING: 'accounting',
    OMEGA: 'omega',
    SHOPTET: 'shoptet',
    EKASA: 'ekasa',
    // TODO
};