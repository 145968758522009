import React from 'react';
import PropTypes from 'prop-types';
import RightsWrapper from '../../sessionProvider/RightsWrapper';
import {RIGHTS} from '../../../constants/Rights';
import {locationHOC} from '../../locationProvider/locationHOC';
import Link from '../../navigation/Link';
import {getRelativePath} from '../../../lib/url';
import {ROUTES, PARENT_ROUTES} from '../../../constants/navigation';
import {Trans, t} from '@lingui/macro';
import withSessionHOC from '../../sessionProvider/withSessionHOC';
import { SESSION_ATTRIBUTES } from '../../sessionProvider/SessionProvider';
import { STOCK_ROOT } from '../../../constants/apiEndpoints';
import {Badge} from 'antd';
import { GLOBAL_DATA } from '../../../constants/globalData';
import { UserInfo } from '../../../constants/propTypesDefinitions';
import withDataHOC from '../../dataProvider/withDataHOC';
import ModuleWrapper from '../../sessionProvider/ModuleWrapper';
import { ENTITY } from '../../../constants/modules';

/**
 * Defines header navigation tabs.
 *
 * @fero
 */

class HeaderTabs extends React.PureComponent {
    static propTypes = {
        location: PropTypes.object.isRequired,
        [GLOBAL_DATA.ACCOUNT_INFO]: UserInfo.isRequired,
    };

    render() {
        const {location, [SESSION_ATTRIBUTES.IS_MOBILE]: isMobile, [SESSION_ATTRIBUTES.SETTINGS]: settings,
             [GLOBAL_DATA.ACCOUNT_INFO]: userInfo = {}} = this.props;
        const relativePath = getRelativePath(location.pathname);
        const modules = settings != null && Array.isArray(settings.modules) ? settings.modules : [];
        const hasEshop = modules.includes(ENTITY.ESHOP);

        return <div>
            <div className="d-flex flex-wrap justify-content-center header-navigation">
                { isMobile ? 
                    <Tab
                        path={ROUTES.ACCOUNT}
                        currentPath={relativePath}
                        title={<Trans>Moj účet</Trans>}
                        location={location}
                    /> : null                      
                }
                <Tab
                    path={ROUTES.HOME}
                    currentPath={relativePath}
                    title={hasEshop ? <Trans>Produkty</Trans> : <Trans>Domov</Trans>}
                    location={location}
                />
                <ModuleWrapper mod={ENTITY.ESHOP}>
                    <RightsWrapper from={RIGHTS.WHOLESALE_CUSTOMER} deny={[RIGHTS.WORKER, RIGHTS.MARKETING]}>
                        <Tab
                            path={ROUTES.CONTRACT}
                            currentPath={relativePath}
                            title={<Trans>Kontrakt</Trans>}
                            location={location}
                        />
                    </RightsWrapper>
                    <RightsWrapper from={RIGHTS.MARKETING} deny={[RIGHTS.WORKER]}>
                        <Tab
                            path={ROUTES.NEWS}
                            currentPath={relativePath}
                            title={<Trans>Novinky</Trans>}
                            location={location}
                        />
                    </RightsWrapper>
                    <RightsWrapper from={RIGHTS.DEALER}>
                        <Tab
                            path={ROUTES.DISCOUNT_RULES}
                            currentPath={relativePath}
                            title={<Trans>Rabaty</Trans>}
                            location={location}
                        />
                    </RightsWrapper>
                </ModuleWrapper>
                <RightsWrapper from={RIGHTS.MARKETING}>
                    <Tab
                        path={ROUTES.CUSTOMERS}
                        currentPath={relativePath}
                        title={<Trans>Organizácie</Trans>}
                        location={location}
                    />
                </RightsWrapper>
                <ModuleWrapper mod={ENTITY.QUOTATIONS}>
                    <RightsWrapper from={RIGHTS.CUSTOMER} deny={[RIGHTS.WORKER]}>
                        <Tab
                            path={ROUTES.REQUESTS}
                            currentPath={relativePath}
                            title={<Trans>Dopyty</Trans>}
                            location={location}
                        />
                        <Tab
                            path={ROUTES.QUOTATIONS}
                            currentPath={relativePath}
                            title={<Trans>Cenové ponuky</Trans>}
                            location={location}
                        />
                    </RightsWrapper>
                </ModuleWrapper>
                <ModuleWrapper mod={ENTITY.ESHOP}>
                    <RightsWrapper from={RIGHTS.CUSTOMER}>
                        <Tab
                            path={ROUTES.ORDERS}
                            currentPath={relativePath}
                            title={<Trans>Objednávky</Trans>}
                            location={location}
                        />
                    </RightsWrapper>
                    <RightsWrapper from={RIGHTS.CUSTOMER}>
                        <ModuleWrapper mod={ENTITY.ACCOUNTING}>
                            <Tab
                                path={ROUTES.INVOICES}
                                currentPath={relativePath}
                                title={<Trans>Faktúry</Trans>}
                                location={location}
                            />
                        </ModuleWrapper>
                    </RightsWrapper>
                    <RightsWrapper from={RIGHTS.PENDING} deny={[RIGHTS.WORKER]}>
                        <Tab
                            path={ROUTES.SHOPPING_LISTS}
                            currentPath={relativePath}
                            title={<Trans>Nákupné zoznamy</Trans>}
                            location={location}
                        />
                    </RightsWrapper>
                </ModuleWrapper>
                <RightsWrapper from={RIGHTS.MARKETING} deny={[RIGHTS.WORKER]}>
                    <ModuleWrapper mod={ENTITY.ANALYSIS}>
                        <Tab
                            path={ROUTES.ANALYSIS}
                            currentPath={relativePath}
                            title={<Trans>Analýzy</Trans>}
                            location={location}
                        />
                    </ModuleWrapper>
                    <ModuleWrapper mod={ENTITY.ALERTS}>
                        <Tab
                            path={ROUTES.ALERTS}
                            currentPath={relativePath}
                            title={<Trans>Upozornenia</Trans>}
                            location={location}
                            count={userInfo.alerts_count}
                        />
                    </ModuleWrapper>
                    <ModuleWrapper mod={ENTITY.REPORTS}>
                        <Tab
                            path={ROUTES.REPORTS}
                            currentPath={relativePath}
                            title={<Trans>Reporty</Trans>}
                            location={location}
                        />
                    </ModuleWrapper>
                </RightsWrapper>
                <RightsWrapper from={RIGHTS.MARKETING}>
                    <ModuleWrapper mod={ENTITY.EVENTS}>
                        <Tab
                            path={ROUTES.EVENTS}
                            currentPath={relativePath}
                            title={<Trans>Eventy</Trans>}
                            location={location}
                        />
                    </ModuleWrapper>
                </RightsWrapper>
                { settings.is_license_server ?
                    <RightsWrapper from={RIGHTS.DEALER}>
                        <Tab
                            path={ROUTES.SUBSCRIPTIONS}
                            currentPath={relativePath}
                            title={<Trans>Licencie</Trans>}
                            location={location}
                        />
                    </RightsWrapper>
                    : null
                }
                <RightsWrapper from={RIGHTS.WORKER}>
                    <ModuleWrapper mod={ENTITY.STOCK}>
                        <Tab
                            externalPath={STOCK_ROOT}
                            currentPath={relativePath}
                            title={<Trans>Sklad</Trans>}
                            location={location}
                        />
                    </ModuleWrapper>
                </RightsWrapper>
                <RightsWrapper from={RIGHTS.SUPERVISOR}>
                    <Tab
                        path={ROUTES.ADMINISTRATION_MANUFACTURERS}
                        currentPath={relativePath}
                        title={<Trans>Administrácia</Trans>}
                        location={location}
                        childrenPaths={[
                            ROUTES.ADMINISTRATION_MANUFACTURERS,
                            ROUTES.ADMINISTRATION_EVENT_TYPES,
                            ROUTES.ADMINISTRATION_FLAGS,
                            ROUTES.ADMINISTRATION_VEHICLES,
                            ROUTES.ADMINISTRATION_CUSTOMER_GROUPS,
                            ROUTES.ADMINISTRATION_INVENTORY_CLASSES,
                            ROUTES.ADMINISTRATION_IMPORT_EXPORT,
                            ROUTES.ADMINISTRATION_DEPARTMENTS,
                            ROUTES.ADMINISTRATION_FORMS,
                            ROUTES.ADMINISTRATION_LICENSE,
                            ROUTES.ADMINISTRATION_BACKUP,
                            ROUTES.ADMINISTRATION_SETTINGS,
                        ]}
                    />
                </RightsWrapper>
            </div>
            <div className="header-navigation-2-level">
                <RightsWrapper from={RIGHTS.DEALER}>
                    <Admin2LevelNavGroup
                        key="adminLevel2navigation"
                        location={location}
                        settings={settings}
                        currentPath={relativePath}
                    />
                </RightsWrapper>
            </div>
        </div>;
    }
}

//tab for first level of header navigation
const Tab = (props) => {
    const {title, path, externalPath, currentPath, childrenPaths, location, queryParams, count} = props;
    const isSelected = currentPath == path || (Array.isArray(childrenPaths) && childrenPaths.includes(currentPath));
    const className = 'list-item header-navigation-tab d-inline-block no-decoration-link ' + (isSelected ? ' list-item-selected' : '');
    const label = count != null ? <Badge count={count} size="small">{title}</Badge> : title;
    return externalPath != null ? 
    <a target="_blank" href={externalPath} className={className}>
        {label}
    </a> :
    <Link
        location={location}
        to={path}
        queryParams={queryParams}
        className={className}
    >
        {label}
    </Link>
};

//tab for second level of header navigation
const Tab2Level = (props) => {
    const {queryParams, location, title, path, currentPath, parentPath} = props;
    if (currentPath.includes(parentPath)) {
        const isSelected = currentPath == path;
        return <Link
            location={location}
            to={path}
            queryParams={queryParams}
            className={'d-inline-block header-navigation-2-level-tab no-decoration-link' + (isSelected ? '  header-navigation-2-level-tab-active' : '')}
        >
            {title}
        </Link>
    } else {
        return null;
    }
};

const Admin2LevelNavGroup = (props) => {
    const {currentPath, location, settings} = props;
    return <React.Fragment>
        <Tab2Level
            path={ROUTES.ADMINISTRATION_MANUFACTURERS}
            currentPath={currentPath}
            title={<Trans>Výrobcovia</Trans>}
            location={location}
            queryParams={{}}
            parentPath={PARENT_ROUTES.ADMINISTRATION}
        />
        <ModuleWrapper mod={ENTITY.STOCK}>
            <Tab2Level
                path={ROUTES.ADMINISTRATION_INVENTORY_CLASSES}
                currentPath={currentPath}
                title={<Trans>Skladové kategórie</Trans>}
                location={location}
                queryParams={{}}
                parentPath={PARENT_ROUTES.ADMINISTRATION}
            />
        </ModuleWrapper>
        <ModuleWrapper mod={ENTITY.EVENTS}>
            <Tab2Level
                path={ROUTES.ADMINISTRATION_VEHICLES}
                currentPath={currentPath}
                title={<Trans>Vozidlá</Trans>}
                location={location}
                queryParams={{}}
                parentPath={PARENT_ROUTES.ADMINISTRATION}
            />
            <Tab2Level
                path={ROUTES.ADMINISTRATION_EVENT_TYPES}
                currentPath={currentPath}
                title={<Trans>Typy eventov</Trans>}
                location={location}
                queryParams={{}}
                parentPath={PARENT_ROUTES.ADMINISTRATION}
            />
        </ModuleWrapper>
        <ModuleWrapper mod={ENTITY.ESHOP}>
            <Tab2Level
                path={ROUTES.ADMINISTRATION_FLAGS}
                currentPath={currentPath}
                title={<Trans>Príznaky produktov</Trans>}
                location={location}
                queryParams={{}}
                parentPath={PARENT_ROUTES.ADMINISTRATION}
            />
        </ModuleWrapper>
        <Tab2Level
            path={ROUTES.ADMINISTRATION_IMPORT_EXPORT}
            currentPath={currentPath}
            title={<Trans>Import/Export</Trans>}
            location={location}
            queryParams={{}}
            parentPath={PARENT_ROUTES.ADMINISTRATION}
        />
        <Tab2Level
            path={ROUTES.ADMINISTRATION_CUSTOMER_GROUPS}
            currentPath={currentPath}
            title={<Trans>Zákaznícke skupiny</Trans>}
            location={location}
            queryParams={{}}
            parentPath={PARENT_ROUTES.ADMINISTRATION}
        />
        <Tab2Level
            path={ROUTES.ADMINISTRATION_DEPARTMENTS}
            currentPath={currentPath}
            title={<Trans>Oddelenia</Trans>}
            location={location}
            queryParams={{}}
            parentPath={PARENT_ROUTES.ADMINISTRATION}
        />
        <ModuleWrapper mod={ENTITY.FORMS}>
            <Tab2Level
                key="forms"
                path={ROUTES.ADMINISTRATION_FORMS}
                currentPath={currentPath}
                title={<Trans>Kustomizácie</Trans>}
                location={location}
                queryParams={{}}
                parentPath={PARENT_ROUTES.ADMINISTRATION}
            />
        </ModuleWrapper>
        { !settings.is_license_server ?
            <RightsWrapper from={RIGHTS.MANAGER}>
                <Tab2Level
                    key="license"
                    path={ROUTES.ADMINISTRATION_LICENSE}
                    currentPath={currentPath}
                    title={<Trans>Licencia</Trans>}
                    location={location}
                    queryParams={{}}
                    parentPath={PARENT_ROUTES.ADMINISTRATION}
                />
            </RightsWrapper>
            : null
        }
        <Tab2Level
            key="history"
            path={ROUTES.ADMINISTRATION_HISTORY}
            currentPath={currentPath}
            title={<Trans>História</Trans>}
            location={location}
            queryParams={{}}
            parentPath={PARENT_ROUTES.ADMINISTRATION}
        />
        <RightsWrapper key="backup" from={RIGHTS.ADMIN}>
        <Tab2Level
            path={ROUTES.ADMINISTRATION_BACKUP}
            currentPath={currentPath}
            title={<Trans>Zálohovanie</Trans>}
            location={location}
            queryParams={{}}
            parentPath={PARENT_ROUTES.ADMINISTRATION}
        />
        </RightsWrapper>
        <RightsWrapper key="settings" from={RIGHTS.MANAGER}>
        <Tab2Level
            path={ROUTES.ADMINISTRATION_SETTINGS}
            currentPath={currentPath}
            title={<Trans>Všeobecné nastavenia</Trans>}
            location={location}
            queryParams={{}}
            parentPath={PARENT_ROUTES.ADMINISTRATION}
        />
        </RightsWrapper>
    </React.Fragment>
};

export default locationHOC(
    withSessionHOC([SESSION_ATTRIBUTES.IS_MOBILE, SESSION_ATTRIBUTES.SETTINGS])(
        withDataHOC([GLOBAL_DATA.ACCOUNT_INFO])(HeaderTabs)
    )
);