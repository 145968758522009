import React from 'react';
import PropTypes from 'prop-types';
import RightsWrapper from '../sessionProvider/RightsWrapper';
import Login from '../login/Login';
import Register from '../login/Register';
import CustomersSelectHeader from './header/CustomersSelectHeader';
import UserDropDown from './header/UserDropDown';
import HeaderTabs from './header/HeaderTabs';
import {locationHOC} from '../locationProvider/locationHOC';
import {navigateToParametrized} from '../../lib/url';
import {Trans, t} from '@lingui/macro';
import {RIGHTS} from '../../constants/Rights';
import {ROUTES} from '../../constants/navigation';
import CartButton from '../cart/CartButton';
import withSessionHOC from '../sessionProvider/withSessionHOC';
import { SESSION_ATTRIBUTES } from '../sessionProvider/SessionProvider';
import SidePanel from './SidePanel';
import CartButtonMobile from '../cart/CartButtonMobile';
import { withPrefix } from 'gatsby';
import LocaleSelect from './LocaleSelect';
import ModuleWrapper from '../sessionProvider/ModuleWrapper';
import { ENTITY } from '../../constants/modules';

/**
 * @fero
 */
class Header extends React.PureComponent {
    static propTypes = {
        location: PropTypes.object.isRequired,
    };

    render() {
        const {location, [SESSION_ATTRIBUTES.IS_MOBILE]: isMobile} = this.props;
        const logo = withPrefix("/config/logo.png");
        return isMobile ? <div>
            <div className="d-flex justify-content-between align-items-center full-size-width">
                <SidePanel/>
                <div
                    className="d-flex align-items-center"
                    onClick={() => {
                        navigateToParametrized(
                            location, ROUTES.HOME,
                            null,
                        )
                    }}
                >
                    <div className="p-2"><img height="60px" src={logo} alt="logo"/></div>
                </div>
                <div className="d-flex align-items-center">
                    <LocaleSelect/>
                    <ModuleWrapper mod={ENTITY.ESHOP}>
                        <CartButtonMobile/>
                    </ModuleWrapper>
                </div>
            </div>
            <RightsWrapper from={RIGHTS.CUSTOMER}>
                <ModuleWrapper mod={ENTITY.ESHOP}>
                    <CustomersSelectHeader/>
                </ModuleWrapper>
            </RightsWrapper>
        </div> :
        <div>
            <div className="d-flex flex-wrap justify-content-around align-items-center header">
                <div
                    className="d-flex flex-grow-1 align-items-center cursor-pointer"
                    onClick={() => {
                        navigateToParametrized(
                            location, ROUTES.HOME,
                            null,
                        )
                    }}
                >
                    <div className="p-2"><img height="75px" src={logo} alt="logo"/></div>
                </div>
                <RightsWrapper to={RIGHTS.WORKER}>
                    <div className="flex-grow-1"></div>
                </RightsWrapper>
                <RightsWrapper from={RIGHTS.CUSTOMER}>
                    <ModuleWrapper mod={ENTITY.ESHOP}>
                        <CustomersSelectHeader/>
                    </ModuleWrapper>
                </RightsWrapper>
                <div className="d-flex align-items-center px-2">
                    <RightsWrapper to={RIGHTS.UNREGISTERED}>
                        <Login/>
                        <ModuleWrapper mod={ENTITY.ESHOP}>
                            <div className="px-2">
                                <Trans>alebo</Trans>
                            </div>
                            <Register/>
                        </ModuleWrapper>
                    </RightsWrapper>
                    <div className="d-flex align-items-center">
                        <LocaleSelect/>
                        <ModuleWrapper mod={ENTITY.ESHOP}>
                            <CartButton/>
                        </ModuleWrapper>
                    </div>
                    <RightsWrapper from={RIGHTS.PENDING}>
                        <UserDropDown/>
                    </RightsWrapper>
                </div>
            </div>
            <RightsWrapper from={RIGHTS.PENDING}>
                <div className="full-size-width">
                    <HeaderTabs/>
                </div>
            </RightsWrapper>
        </div>;
    }

}

export default withSessionHOC([SESSION_ATTRIBUTES.IS_MOBILE])(locationHOC(Header));